var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.modalDataPresent
        ? _c(
            "si-modal",
            {
              ref: "taskDetailModal",
              attrs: { modalOptions: _vm.modalOptions },
              on: {
                escapePressed: _vm.hideModal,
                onDismiss: function($event) {
                  return _vm.handleModalClose()
                }
              }
            },
            [
              _c("template", { slot: "modal-content" }, [
                !_vm.task.is_archived || _vm.currentUser.owner_or_super_admin
                  ? _c(
                      "div",
                      { staticClass: "row row-20 log-time-header-row" },
                      [
                        _vm.task.cancelled_at
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "alert alert-danger m-b-0 text-center"
                              },
                              [
                                _c("span", {
                                  staticClass:
                                    "btn-icon-helper cancel-task-info-icon"
                                }),
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      this.$i18n.t("task_modal.cancelled_text")
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm._f("formatSIExpandedDateTime")(
                                        _vm.task.cancelled_at
                                      )
                                    ) +
                                    "\n        "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "p-l-r-20 p-t-b-10 d-flex justify-between"
                          },
                          [
                            _c(
                              "span",
                              [
                                _c("mark-complete-button", {
                                  attrs: {
                                    is_completed: this.task.is_completed,
                                    is_cancelled: this.task.is_cancelled,
                                    task_details: {
                                      id: this.task.id,
                                      details: this.task.title
                                    }
                                  },
                                  on: {
                                    markComplete: _vm.showMarkCompleteModal,
                                    markInComplete: function($event) {
                                      return _vm.markInComplete(_vm.task)
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _vm.currentUser.owner_or_super_admin ||
                                !this.task.is_completed
                                  ? _c(
                                      "span",
                                      { staticClass: "m-l-10" },
                                      [
                                        _c("log-time-button", {
                                          on: {
                                            logTime: _vm.showTimeLogModalForm
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "add-edit-col nowrap" }, [
                              _c("div", { staticClass: "top-action-group" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "dropdown d-inline-block",
                                    attrs: { "data-menu": "dropdown" }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "setting-dropdown-toggle btn-h-40-only btn dropdown-toggle btn-settings normal js-show-button",
                                        attrs: {
                                          type: "button",
                                          "data-toggle": "dropdown",
                                          "aria-expanded": "false"
                                        }
                                      },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "btn-icon-helper gear-icon m-r-r"
                                        }),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "caret" })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    !_vm.task.is_archived ||
                                    _vm.currentUser.owner_or_super_admin
                                      ? _c(
                                          "ul",
                                          {
                                            staticClass:
                                              "dropdown-menu settings-dropdown dropdown-menu-right"
                                          },
                                          [
                                            !_vm.task.restrict_editable
                                              ? _c(
                                                  "li",
                                                  {
                                                    attrs: {
                                                      "data-task-complete-field":
                                                        _vm.task.id
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "sweet-alert",
                                                      {
                                                        attrs: {
                                                          container_classes:
                                                            "dropdown-div d-flex"
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            return _vm.deleteTask(
                                                              _vm.task.id
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "full-width del",
                                                              attrs: {
                                                                href:
                                                                  "javascript:void(0)",
                                                                "data-sweet-alert-confirm":
                                                                  "Are you sure you want to delete this task?",
                                                                "data-heading":
                                                                  "Delete Task",
                                                                "data-task-complete-field":
                                                                  _vm.task.id,
                                                                "data-confirm-type":
                                                                  "delete",
                                                                "data-description": this.$i18n.t(
                                                                  "vue_task_list.entity_details",
                                                                  {
                                                                    entity_id:
                                                                      _vm.task
                                                                        .id,
                                                                    entity_title:
                                                                      _vm.task
                                                                        .title
                                                                  }
                                                                ),
                                                                "data-vue-component":
                                                                  "true",
                                                                "data-confirm-button-text": this.$i18n.tc(
                                                                  "vue_task_list.delete_permanently"
                                                                ),
                                                                "data-cancel-button-text": this.$i18n.tc(
                                                                  "vue_task_list.cancel"
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  this.$i18n.tc(
                                                                    "vue_task_list.delete"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ],
                                                      2
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !_vm.task.is_cancelled &&
                                            !_vm.task.is_archived
                                              ? _c(
                                                  "li",
                                                  [
                                                    _c(
                                                      "sweet-alert",
                                                      {
                                                        attrs: {
                                                          container_classes:
                                                            "dropdown-div d-flex"
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            return _vm.cancelTask(
                                                              _vm.task.id
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "full-width",
                                                            attrs: {
                                                              href:
                                                                "javascript:void(0)",
                                                              "data-heading":
                                                                "Cancel Task",
                                                              "data-task-complete-field":
                                                                _vm.task.id,
                                                              "data-confirm-type":
                                                                "cancel",
                                                              "data-description": this.$i18n.t(
                                                                "vue_task_list.entity_details",
                                                                {
                                                                  entity_id:
                                                                    _vm.task.id,
                                                                  entity_title:
                                                                    _vm.task
                                                                      .title
                                                                }
                                                              ),
                                                              "data-vue-component":
                                                                "true",
                                                              "data-sweet-alert-confirm":
                                                                "Are you sure you want to cancel this task?",
                                                              "data-confirm-button-text": this.$i18n.tc(
                                                                "vue_task_list.cancel_task_button_text"
                                                              ),
                                                              "data-cancel-button-text": this.$i18n.tc(
                                                                "vue_task_list.discard"
                                                              )
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                this.$i18n.tc(
                                                                  "vue_task_list.cancel"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !_vm.task.is_archived
                                              ? _c(
                                                  "li",
                                                  [
                                                    _c(
                                                      "sweet-alert",
                                                      {
                                                        attrs: {
                                                          container_classes:
                                                            "dropdown-div d-flex"
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            return _vm.archiveTask(
                                                              _vm.task.id
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "full-width",
                                                            attrs: {
                                                              href:
                                                                "javascript:void(0)",
                                                              "data-sweet-alert-confirm":
                                                                "Are you sure you want to archive this task?",
                                                              "data-heading":
                                                                "Archive Task",
                                                              "data-task-complete-field":
                                                                _vm.task.id,
                                                              "data-confirm-type":
                                                                "archive",
                                                              "data-description": this.$i18n.t(
                                                                "vue_task_list.entity_details",
                                                                {
                                                                  entity_id:
                                                                    _vm.task.id,
                                                                  entity_title:
                                                                    _vm.task
                                                                      .title
                                                                }
                                                              ),
                                                              "data-vue-component":
                                                                "true",
                                                              "data-confirm-button-text": this.$i18n.tc(
                                                                "vue_task_list.archive"
                                                              ),
                                                              "data-cancel-button-text": this.$i18n.tc(
                                                                "vue_task_list.cancel"
                                                              )
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                this.$i18n.tc(
                                                                  "vue_task_list.archive"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.task.netsuite_id
                                              ? _c("li", [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        target: "_blank",
                                                        href:
                                                          _vm.task.netsuite_link
                                                      }
                                                    },
                                                    [_vm._v("Netsuite Link")]
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ])
                            ])
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                this.task
                  ? _c("div", [
                      _c(
                        "form",
                        {
                          staticClass: "form",
                          attrs: {
                            "data-maxFileSize": "524288000",
                            "data-attachment-form": "true",
                            "data-upload-url": "/admin/attachments"
                          },
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                            }
                          }
                        },
                        [
                          _c("article", {}, [
                            _c(
                              "div",
                              {
                                staticClass: "form-group m-b-0",
                                attrs: { "data-error-container": "form-group" }
                              },
                              [
                                _c("in-place-editor", {
                                  attrs: {
                                    placeholder: this.$tc(
                                      "task_modal.title_placeholder"
                                    ),
                                    input_value_class:
                                      "task-title-edit-textarea",
                                    display_text_class:
                                      "task-title-edit-textarea hover-elem",
                                    can_be_edited:
                                      !_vm.task.is_archived ||
                                      _vm.currentUser.owner_or_super_admin,
                                    value: _vm.task.title
                                  },
                                  on: {
                                    editable: _vm.escapePressed,
                                    changed: function($event) {
                                      return _vm.submitForm()
                                    }
                                  },
                                  model: {
                                    value: _vm.task.title,
                                    callback: function($$v) {
                                      _vm.$set(_vm.task, "title", $$v)
                                    },
                                    expression: "task.title"
                                  }
                                }),
                                _vm._v(" "),
                                _c("error", {
                                  attrs: {
                                    errors:
                                      _vm.task.errors && _vm.task.errors.title
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "article",
                            { staticClass: "task-dm-tab-section" },
                            [
                              _c(
                                "ul",
                                {
                                  ref: "tabList",
                                  staticClass:
                                    "task-dm-tabs d-flex lh-38 list-unstyled",
                                  attrs: { role: "tablist" }
                                },
                                [
                                  _c(
                                    "li",
                                    {
                                      ref: "detailsTab",
                                      staticClass: "tab-li active",
                                      attrs: { role: "presentation" }
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "t-tab-link",
                                          attrs: {
                                            href: "#details",
                                            "aria-controls": "details",
                                            role: "tab",
                                            "data-toggle": "tab"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.t(
                                                "task_modal.tabs.details"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    {
                                      ref: "commentsTab",
                                      staticClass: "tab-li",
                                      attrs: { role: "presentation" }
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "t-tab-link",
                                          attrs: {
                                            href: "#comments",
                                            "aria-controls": "comments",
                                            role: "tab",
                                            "data-toggle": "tab"
                                          },
                                          on: { click: _vm.loadComments }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.t(
                                                "task_modal.tabs.comments"
                                              )
                                            ) + " "
                                          ),
                                          _vm.task.comments_count > 0
                                            ? _c("span", [
                                                _vm._v(
                                                  "(" +
                                                    _vm._s(
                                                      _vm.task.comments_count
                                                    ) +
                                                    ")"
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    {
                                      ref: "timeLogTab",
                                      staticClass: "tab-li",
                                      attrs: { role: "presentation" }
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "t-tab-link",
                                          attrs: {
                                            href: "#timelogs",
                                            "aria-controls": "timelogs",
                                            role: "tab",
                                            "data-toggle": "tab"
                                          },
                                          on: { click: _vm.loadTimeLogs }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.t(
                                                "task_modal.tabs.timelogs"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    {
                                      ref: "historyTab",
                                      staticClass: "tab-li",
                                      attrs: { role: "presentation" }
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "t-tab-link",
                                          attrs: {
                                            href: "#history",
                                            "aria-controls": "history",
                                            role: "tab",
                                            "data-toggle": "tab"
                                          },
                                          on: { click: _vm.loadHistory }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.t(
                                                "task_modal.tabs.history"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "tab-content m-t-10 hide-tooltip"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "tab-pane active",
                                      attrs: { role: "tabpanel", id: "details" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "row row-col-space-8 m-l-0 m-r-0"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-md-3 hover-elem p-l-0"
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "d-flex align-center justify-between m-b-0-hard lh-40 pointer",
                                                  attrs: { for: "date_picker" }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        this.$i18n.t(
                                                          "task_modal.details.due_date_label"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "svg",
                                                    {
                                                      attrs: {
                                                        "data-v-3d990fac": "",
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "24",
                                                        height: "24",
                                                        viewBox: "0 0 24 24"
                                                      }
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "data-v-3d990fac": "",
                                                          fill: "#a1a8c3",
                                                          "fill-rule":
                                                            "evenodd",
                                                          d:
                                                            "M15 7H9v1.5a.5.5 0 0 1-1 0V7H6a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1h-2v1.5a.5.5 0 1 1-1 0V7zm1-1h2a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h2V4.5a.5.5 0 0 1 1 0V6h6V4.5a.5.5 0 1 1 1 0V6zM5 11h14v1H5v-1z"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "col-md-9 hover-elem"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "lh-40 dark task-date-editable"
                                                },
                                                [
                                                  !_vm.task.is_archived ||
                                                  _vm.currentUser
                                                    .owner_or_super_admin
                                                    ? _c("date-field", {
                                                        ref: "dateField",
                                                        attrs: {
                                                          not_format: true,
                                                          id: "date_picker"
                                                        },
                                                        on: {
                                                          focusIn:
                                                            _vm.escapePressed,
                                                          changed:
                                                            _vm.dueDateChanged
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.task
                                                              .virtual_due_date,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.task,
                                                              "virtual_due_date",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "task.virtual_due_date"
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.task.is_archived &&
                                                  !_vm.currentUser
                                                    .owner_or_super_admin
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.task
                                                              .virtual_due_date
                                                          )
                                                        )
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    { staticClass: "m-r-5" },
                                                    [_vm._v("-")]
                                                  ),
                                                  _vm._v(" "),
                                                  !_vm.task.is_archived ||
                                                  _vm.currentUser
                                                    .owner_or_super_admin
                                                    ? _c("time-picker", {
                                                        ref: "timeField",
                                                        attrs: {
                                                          time_icon_class:
                                                            "hide",
                                                          field_value:
                                                            _vm.task
                                                              .virtual_due_time,
                                                          field_name:
                                                            "task[virtual_due_time]",
                                                          element_id:
                                                            "task_due_time",
                                                          placeholder:
                                                            "08:00 AM"
                                                        },
                                                        on: {
                                                          focusIn:
                                                            _vm.escapePressed,
                                                          time_value_update:
                                                            _vm.dueDateChanged
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.task
                                                              .virtual_due_time,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.task,
                                                              "virtual_due_time",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "task.virtual_due_time"
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.task.is_archived &&
                                                  !_vm.currentUser
                                                    .owner_or_super_admin
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.task
                                                              .virtual_due_time
                                                          )
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("error", {
                                                attrs: {
                                                  errors:
                                                    _vm.task.errors &&
                                                    (_vm.task.errors
                                                      .virtual_due_time ||
                                                      _vm.task.errors
                                                        .due_time ||
                                                      _vm.task.errors
                                                        .virtual_due_date ||
                                                        _vm.task.errors
                                                          .due_date)
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "row row-col-space-8 m-l-0 m-r-0"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-md-3 hover-elem p-l-0"
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "d-flex align-center justify-between m-b-0-hard lh-40",
                                                  attrs: { for: "" }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        this.$i18n.t(
                                                          "task_modal.details.assignee_label"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass: "pointer",
                                                      attrs: {
                                                        "data-v-3d990fac": "",
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "24",
                                                        height: "24",
                                                        viewBox: "0 0 24 24"
                                                      },
                                                      on: {
                                                        click: _vm.editAssignee
                                                      }
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "data-v-3d990fac": "",
                                                          fill: "#a1a8c3",
                                                          "fill-rule":
                                                            "evenodd",
                                                          d:
                                                            "M16.327 17.503a4.991 4.991 0 0 0-8.654 0A6.97 6.97 0 0 0 12 19a6.97 6.97 0 0 0 4.327-1.497zm-9.407-.687a5.991 5.991 0 0 1 10.16 0 7 7 0 1 0-10.16 0zM12 13a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-1a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 8a8 8 0 1 1 0-16 8 8 0 0 1 0 16z"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.task.assignee
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-md-9 hover-elem lh-40"
                                                },
                                                [
                                                  !_vm.edit_assignee
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex align-center pointer",
                                                          on: {
                                                            click:
                                                              _vm.editAssignee
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "user-popover-tooltip",
                                                            {
                                                              attrs: {
                                                                user_id:
                                                                  _vm.assigneeId
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "card-assign-user normal x-20"
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    staticClass:
                                                                      "img-responsive",
                                                                    attrs: {
                                                                      src:
                                                                        _vm.task
                                                                          .assignee
                                                                          .profile_picture_url
                                                                    }
                                                                  })
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "dark fontmedium m-l-10"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.task
                                                                        .assignee
                                                                        .actual_name
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c("user-selector", {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.edit_assignee,
                                                        expression:
                                                          "edit_assignee"
                                                      }
                                                    ],
                                                    ref: "assigneeSelector",
                                                    attrs: {
                                                      user_options:
                                                        _vm.assignee_options,
                                                      selected_option:
                                                        _vm.selectedAssignee,
                                                      clearable: false
                                                    },
                                                    on: {
                                                      userSelected:
                                                        _vm.userChanged,
                                                      focus_out: function(
                                                        $event
                                                      ) {
                                                        _vm.edit_assignee = false
                                                      },
                                                      escapePressed:
                                                        _vm.escapePressed
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "row row-col-space-8 m-l-0 m-r-0"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-md-3 hover-elem p-l-0"
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "d-flex align-center justify-between m-b-0-hard lh-40",
                                                  attrs: { for: "" }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        this.$i18n.t(
                                                          "task_modal.details.time_log_label"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "svg",
                                                    {
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "24",
                                                        height: "24",
                                                        viewBox: "0 0 24 24"
                                                      }
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          fill: "#A1A8C3",
                                                          "fill-rule":
                                                            "evenodd",
                                                          d:
                                                            "M12 12h3.833a.5.5 0 1 1 0 1H11.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0V12zm0 8a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm0-1a7 7 0 1 0 0-14 7 7 0 0 0 0 14z"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-md-9 hover-elem dark lh-40"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  ref: "timeLoggedValue",
                                                  staticClass:
                                                    "d-flex align-center fontmedium justify-between"
                                                },
                                                [
                                                  _c("span", {}, [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "timeDurationFormat"
                                                        )(
                                                          _vm.task
                                                            .total_time_logged
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  ((!this.task.is_completed ||
                                                    _vm.currentUser
                                                      .owner_or_super_admin) &&
                                                    !_vm.task.is_archived) ||
                                                  _vm.currentUser
                                                    .owner_or_super_admin
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text blue show-on-hover m-r-15 pointer",
                                                          on: {
                                                            click:
                                                              _vm.showTimeLogModalForm
                                                          }
                                                        },
                                                        [_vm._v("+ Log Time")]
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "row row-col-space-8 m-l-0 m-r-0"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-md-3 hover-elem p-l-0"
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "d-flex align-center justify-between m-b-0-hard lh-40",
                                                  attrs: {
                                                    for: "task_followers"
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        this.$i18n.t(
                                                          "task_modal.details.followers_label"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass: "pointer",
                                                      attrs: {
                                                        "data-v-3d990fac": "",
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "24",
                                                        height: "24",
                                                        viewBox: "0 0 24 24"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.editFollowers(
                                                            true
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "data-v-3d990fac": "",
                                                          fill: "#a1a8c3",
                                                          "fill-rule":
                                                            "evenodd",
                                                          d:
                                                            "M18.864 14.648c.668.172 1.136.793 1.136 1.505v3.33a.509.509 0 0 1-.5.517h-3a.509.509 0 0 1-.5-.517c0-.286.224-.517.5-.517H19v-2.813a.514.514 0 0 0-.378-.502l-3.243-.839a.511.511 0 0 1-.374-.429l-.253-1.835a.521.521 0 0 1 .287-.543c.89-.42 1.46-1.339 1.461-2.35v-.914c.024-1.276-.827-2.391-2.018-2.661a2.46 2.46 0 0 0-1.316.102.496.496 0 0 1-.638-.317.522.522 0 0 1 .306-.66 3.44 3.44 0 0 1 1.848-.138c1.67.378 2.85 1.925 2.818 3.684v.904c-.001 1.284-.658 2.46-1.708 3.108l.156 1.13 2.916.755zm-4.952 1.537c.644.19 1.088.799 1.088 1.492v1.806a.509.509 0 0 1-.5.517h-10a.509.509 0 0 1-.5-.517v-1.807c0-.692.444-1.3 1.088-1.491l2.463-.728.12-.868a3.948 3.948 0 0 1-2.483-.909.53.53 0 0 1-.09-.71c.67-.945.99-2.107.902-3.315 0-2 1.567-3.62 3.5-3.62s3.5 1.62 3.499 3.66a5.013 5.013 0 0 0 .904 3.274.53.53 0 0 1-.091.711 3.948 3.948 0 0 1-2.482.909l.12.868 2.462.728zM14 17.677a.515.515 0 0 0-.362-.497l-2.775-.82a.512.512 0 0 1-.358-.425l-.253-1.835c-.047-.345.24-.639.573-.584a2.946 2.946 0 0 0 1.985-.379A6.034 6.034 0 0 1 12 9.655c0-1.428-1.12-2.586-2.5-2.586S7 8.227 6.999 9.615a6.09 6.09 0 0 1-.81 3.522 2.946 2.946 0 0 0 1.986.379c.332-.055.62.24.573.584l-.253 1.835a.512.512 0 0 1-.358.424l-2.775.82a.515.515 0 0 0-.362.498v1.289h9v-1.29z"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "col-md-9 pointer",
                                              class: {
                                                "hover-elem": _vm.can_manage
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "wo-follower-block"
                                                },
                                                [
                                                  _c(
                                                    "auto-complete-followers",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            (_vm.edit_followers ||
                                                              _vm.followersEmpty) &&
                                                            (!_vm.task
                                                              .is_archived ||
                                                              _vm.currentUser
                                                                .owner_or_super_admin),
                                                          expression:
                                                            "(edit_followers || followersEmpty) && (!task.is_archived || currentUser.owner_or_super_admin)"
                                                        }
                                                      ],
                                                      ref: "autoComplete",
                                                      attrs: {
                                                        follower_width: "x-32",
                                                        auto_suggest_followers: 8,
                                                        placeholder: _vm.$t(
                                                          "quick_add_task.followers_placeholder"
                                                        ),
                                                        follower_options:
                                                          _vm.follower_options,
                                                        persisted_followers:
                                                          _vm.task
                                                            .task_follower_ids,
                                                        field_for:
                                                          "task_followers"
                                                      },
                                                      on: {
                                                        focusOut:
                                                          _vm.escapePressed,
                                                        escapePressed: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.escapePressed(
                                                            $event
                                                          )
                                                        },
                                                        followersChanged:
                                                          _vm.updateFollower
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.task
                                                            .task_follower_ids,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.task,
                                                            "task_follower_ids",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "task.task_follower_ids"
                                                      }
                                                    }
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.editFollowers(
                                                        false
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.task.followers,
                                                  function(follower) {
                                                    return !_vm.edit_followers
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass: "lh-40"
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                key:
                                                                  follower.id,
                                                                attrs: {
                                                                  href:
                                                                    "javascript:void(0)"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.editFollowers(
                                                                      false
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "pop-over-box d-inline-block follower-tooltip"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "UserProfilePicWithTooltip",
                                                                      {
                                                                        attrs: {
                                                                          user_id:
                                                                            follower.id,
                                                                          image_size:
                                                                            "24"
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "row row-col-space-8 m-l-0 m-r-0",
                                          on: { click: _vm.escapePressed }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-md-3 hover-elem p-l-0"
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "d-flex align-center justify-between m-b-0-hard lh-40",
                                                  attrs: { for: "" }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        this.$i18n.t(
                                                          "task_modal.details.category_label"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "svg",
                                                    {
                                                      attrs: {
                                                        "data-v-3d990fac": "",
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "24",
                                                        height: "24",
                                                        viewBox: "0 0 24 24"
                                                      }
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "data-v-3d990fac": "",
                                                          fill: "#a1a8c3",
                                                          "fill-rule":
                                                            "evenodd",
                                                          d:
                                                            "M6 19h12v-8.281L12 5.18 6 10.72V19zm-1 .5v-9a.5.5 0 0 1 .16-.367l6.5-6a.5.5 0 0 1 .68 0l6.5 6a.5.5 0 0 1 .16.367v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5zm7-6.5a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-3.5 4a.5.5 0 1 1 0-1h7a.5.5 0 1 1 0 1h-7z"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.task.task_category
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-md-9 hover-elem lh-40 dark fontmedium"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.task.task_category
                                                          .name
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.task.listed_taskable
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "row row-col-space-8 m-l-0 m-r-0"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-md-3 hover-elem p-l-0"
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "d-flex align-center justify-between m-b-0-hard lh-40",
                                                      attrs: { for: "" }
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.$i18n.t(
                                                              "task_modal.details.referenced_label"
                                                            )
                                                          )
                                                        )
                                                      ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "svg",
                                                        {
                                                          attrs: {
                                                            "data-v-3d990fac":
                                                              "",
                                                            xmlns:
                                                              "http://www.w3.org/2000/svg",
                                                            width: "24",
                                                            height: "24",
                                                            viewBox: "0 0 24 24"
                                                          }
                                                        },
                                                        [
                                                          _c("path", {
                                                            attrs: {
                                                              "data-v-3d990fac":
                                                                "",
                                                              fill: "#a1a8c3",
                                                              "fill-rule":
                                                                "evenodd",
                                                              d:
                                                                "M6 19h12v-8.281L12 5.18 6 10.72V19zm-1 .5v-9a.5.5 0 0 1 .16-.367l6.5-6a.5.5 0 0 1 .68 0l6.5 6a.5.5 0 0 1 .16.367v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5zm7-6.5a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-3.5 4a.5.5 0 1 1 0-1h7a.5.5 0 1 1 0 1h-7z"
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.task.listed_taskable
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-md-9 hover-elem lh-40 dark fontmedium text-truncate"
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href:
                                                              _vm.entity_link,
                                                            "data-modal-click":
                                                              _vm.modalDataAttribute,
                                                            "data-url":
                                                              _vm.task
                                                                .listed_taskable
                                                                .entity_url
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.task
                                                                .listed_taskable
                                                                .taskable_name
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.task.attachment_ids
                                        ? _c(
                                            "div",
                                            {
                                              attrs: {
                                                "data-attachment-parent": "true"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "task-attachment-container",
                                                  attrs: {
                                                    "data-nested-attachment":
                                                      "container",
                                                    "data-association":
                                                      _vm.association_name,
                                                    "data-nested-association":
                                                      "container"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "task-modal-attachment-group relative"
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "fs-13 attachment-label d-inline-block lh-40 pointer",
                                                          attrs: {
                                                            for:
                                                              "TaskAttachment"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              this.$i18n.tc(
                                                                "quick_add_task.attachments"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "multiple-file-upload",
                                                        {
                                                          class: {
                                                            "attachment-unavailable": !_vm
                                                              .task
                                                              .attachment_ids
                                                              .length,
                                                            "attachment-available":
                                                              _vm.task
                                                                .attachment_ids
                                                                .length
                                                          },
                                                          attrs: {
                                                            value:
                                                              _vm.task
                                                                .attachment_ids,
                                                            association_value_ids:
                                                              _vm.task
                                                                .attachment_ids,
                                                            required_class: "",
                                                            attachment_label_name:
                                                              "TaskAttachment",
                                                            t_add_file:
                                                              "Add File",
                                                            single_attachment: false,
                                                            t_attachments:
                                                              "Attachments",
                                                            file_field_selector:
                                                              "[data-attachment-file-field=true]",
                                                            attachment_type:
                                                              _vm.attachment_type,
                                                            parent_selector:
                                                              "[data-attachment-parent=true]",
                                                            t_file_upload_hint:
                                                              "(max size 500 MB)",
                                                            attachments:
                                                              _vm.task
                                                                .attachables_attachments_attributes
                                                          },
                                                          on: {
                                                            attachment_update: function(
                                                              $event
                                                            ) {
                                                              return _vm.updateValue(
                                                                _vm.task
                                                                  .attachables_attachments_attributes,
                                                                $event
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.task
                                                                .attachment_ids,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.task,
                                                                "attachment_ids",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "task.attachment_ids"
                                                          }
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "row row-col-space-15" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col-md-12" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "description-label-icon m-b-0-hard lh-40",
                                                  attrs: {
                                                    for: "task_description"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "16",
                                                        height: "16",
                                                        viewBox: "0 0 16 16"
                                                      }
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          fill: "#A1A8C3",
                                                          "fill-rule":
                                                            "evenodd",
                                                          d:
                                                            "M.5 3a.5.5 0 1 1 0-1h15a.5.5 0 1 1 0 1H.5zm0 4a.5.5 0 0 1 0-1h9a.5.5 0 0 1 0 1h-9zm0 4a.5.5 0 0 1 0-1h15a.5.5 0 1 1 0 1H.5zm0 4a.5.5 0 1 1 0-1h9a.5.5 0 1 1 0 1h-9z"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("in-place-editor", {
                                                attrs: {
                                                  value: _vm.task.description,
                                                  can_be_edited:
                                                    !_vm.task.is_archived ||
                                                    _vm.currentUser
                                                      .owner_or_super_admin,
                                                  textarea: true,
                                                  container_classes:
                                                    "overflow-y",
                                                  display_text_class:
                                                    "d-height-min form-control place-holder description-textarea p-t-0-hard m-l-r-10",
                                                  input_value_class:
                                                    "mh-66 form-control place-holder description-textarea p-t-0-hard m-l-r-10",
                                                  placeholder: this.$tc(
                                                    "task_modal.description_placeholder"
                                                  )
                                                },
                                                on: {
                                                  editable: _vm.escapePressed,
                                                  changed: _vm.submitForm
                                                },
                                                model: {
                                                  value: _vm.task.description,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.task,
                                                      "description",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "task.description"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("hr"),
                                      _vm._v(" "),
                                      _vm.task.creator
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "row row-col-space-10 p-t-b-5"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-md-12 fs-11 text-center tooltip-inline"
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          this.$i18n.t(
                                                            "task_modal.details.created_by"
                                                          )
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "pointer d-inline-block"
                                                      },
                                                      [
                                                        _c(
                                                          "user-popover-tooltip",
                                                          {
                                                            attrs: {
                                                              user_id:
                                                                _vm.task.creator
                                                                  .id
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(
                                                      ". " +
                                                        _vm._s(
                                                          _vm.task
                                                            .formatted_created_at
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                    !_vm.task.is_newly_created
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "- " +
                                                              _vm._s(
                                                                this.$i18n.t(
                                                                  "task_modal.details.last_edited",
                                                                  {
                                                                    edited:
                                                                      _vm.task
                                                                        .formatted_updated_at
                                                                  }
                                                                )
                                                              )
                                                          )
                                                        ])
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _vm.task
                                                      .last_netsuite_sync_at
                                                      ? _c("span", [
                                                          _vm._v(
                                                            ". Last Netsuited synced at - " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "formatSIDateWithTime"
                                                                )(
                                                                  _vm.task
                                                                    .last_netsuite_sync_at
                                                                )
                                                              )
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ])
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "tab-pane overflow-t-unset",
                                      attrs: {
                                        role: "tabpanel",
                                        id: "comments"
                                      }
                                    },
                                    [
                                      this.loading_comments
                                        ? _c("div", [
                                            _vm._v("Loading Comments...")
                                          ])
                                        : _c(
                                            "div",
                                            [
                                              _c("comment-list", {
                                                ref: "commentsList",
                                                attrs: {
                                                  user_options:
                                                    _vm.follower_options,
                                                  comment_thread:
                                                    _vm.task.comment_thread,
                                                  commentable_uuid:
                                                    _vm.task.uuid,
                                                  comments: _vm.comments_list
                                                },
                                                on: {
                                                  commentDelete:
                                                    _vm.commentsChanged,
                                                  commentUpdated:
                                                    _vm.commentsChanged
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("new-comment", {
                                                ref: "newCommentComponent",
                                                attrs: {
                                                  user_options:
                                                    _vm.follower_options,
                                                  commentable: _vm.task,
                                                  current_user: _vm.currentUser,
                                                  allowed_char_count: 500,
                                                  comment_thread:
                                                    _vm.task.comment_thread
                                                },
                                                on: {
                                                  newCommentAdded: function(
                                                    $event
                                                  ) {
                                                    return _vm.commentsChanged(
                                                      $event
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "tab-pane",
                                      attrs: {
                                        role: "tabpanel",
                                        id: "timelogs"
                                      }
                                    },
                                    [
                                      this.load_timelog
                                        ? _c("task-time-log-list", {
                                            on: {
                                              changed: _vm.loadTimeLogs,
                                              "edit-time-log": function(
                                                $event
                                              ) {
                                                return _vm.editTimeLog($event)
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "tab-pane",
                                      attrs: { role: "tabpanel", id: "history" }
                                    },
                                    [
                                      this.loading_history
                                        ? _c("p", [_vm._v("Loading...")])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !this.loading_history
                                        ? _c("task-history", {
                                            attrs: {
                                              versions: _vm.task.task_versions,
                                              task: _vm.task
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              ])
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }